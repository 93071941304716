import axios from 'axios';
import { message } from './Components/Notify';

/*axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
	? 'https://espaceclient-api.sully-immobilier.fr/api'
	: 'http://localhost:8084/api';*/

axios.defaults.baseURL = 'https://espaceclient-api.sully-immobilier.fr/api'; // https://sully-api.admin-contacts.com/api';

axios.interceptors.response.use(
  function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	return response;
  },
  function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	if (
	  error?.response?.status === 401 &&
	  error.config?.url?.search(/signin/) === -1
	) {
	  // FIXME: Should be 401 need auth, 403 forbidden for User ?
	  localStorage.clear();
	  window.location.reload();
	}

	if (error.message === 'Network Error')
	  message.error('Erreur réseau', 10000);
	return Promise.reject(error?.response);
  }
);

const setToken = (token) => {
  return new Promise((res, rej) => {
	axios.defaults.headers['x-access-token'] = `${token}`;
	res();
  });
};

const userLogin = ({ email, password, token }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.post(`/auth/signin/`, {
		email,
		password,
		token,
	  });
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const userPut = ({ user, data }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.put(`/users/${user.id}`, data);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const userSettingsPut = ({ settingsId, settings }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.put(`/users/settings/${settingsId}`, settings);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const userGet = (user) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(`/users/${user.id}`);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const lotsGet = ({ id }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(`/lots/${id}`);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const programGet = ({ programId }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(`/programs/${programId}`);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const programsNewsGet = ({ programId }) => {
	return new Promise(async (res, rej) => {
		try {
			const resp = await axios.get(`/programsnews/${programId}`);
			res(resp.data);
		} catch (error) {
			rej(error);
		}
	});
};

const callforfundsGet = ({ lotId }) => {
	return new Promise(async (res, rej) => {
		try {
			const resp = await axios.get(`/callforfunds/${lotId}`);
			res(resp.data);
		} catch (error) {
			rej(error);
		}
	});
};

const workflowGet = ({ programId }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(`/programs/workflow/${programId}`);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const newPasswordPost = ({ userId, oldPassword, newPassword }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.post(`/auth/newpassword/`, {
		id: userId,
		password: oldPassword,
		newPassword,
	  });
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const uploadPost = ({
  file,
  lotId,
  programId,
  typeId,
  description,
  callback,
  reservationId
}) => {
  return new Promise(async (res, rej) => {
	try {
	  const config = {
		onUploadProgress: function (progressEvent) {
		  let percentCompleted = Math.round(
			(progressEvent.loaded * 100) / progressEvent.total
		  );
		  callback && callback(percentCompleted);
		},
	  };

	  let data = new FormData(); //TODO : reservationId
	  data.append('file', file);
	  data.append('lotId', lotId);
	  data.append('programId', programId);
	  data.append('reservationId', reservationId);
	  data.append('typeId', typeId);
	  data.append('description', description);

	  const resp = await axios.post(`/objects/`, data, config);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const documentsGet = ({ reservationId, programId }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(`/objects/${reservationId}/${programId}`);
	  res(resp.data);
	} catch (error) {
	  rej(error);
	}
  });
};

const fileGet = ({ lotId, programId, filename, download }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(
		`/objects/?lotId=${lotId}&filename=${filename}&programId=${programId}`,
		{
		  responseType: 'blob',
		}
	  );

	  const file = new Blob([resp.data]);
	  console.log(file);
	  const fileURL = URL.createObjectURL(file);
	  if (download) {
		const link = document.createElement('a');
		link.href = fileURL;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	  }
	  var reader = new FileReader();
	  reader.readAsDataURL(file);
	  reader.onloadend = function () {
		var base64data = reader.result;
		res({ data: base64data, objectUrl: fileURL });
		URL.revokeObjectURL(fileURL);
	  };
	} catch (error) {
	  rej(error);
	}
  });
};

const fileGetByPath = ({ filename, download }) => {
  return new Promise(async (res, rej) => {
	try {
	  const resp = await axios.get(`/objects/?filename=${filename}`, {
		responseType: 'blob',
	  });

	  const file = new Blob([resp.data]);
	  const fileURL = URL.createObjectURL(file);
	  if (download) window.open(fileURL);
	  var reader = new FileReader();
	  reader.readAsDataURL(file);
	  reader.onloadend = function () {
		var base64data = reader.result;
		res({ data: base64data, objectUrl: fileURL, filename: filename });
		URL.revokeObjectURL(fileURL);
	  };
	} catch (error) {
	  rej(error);
	}
  });
};

export {
  userLogin,
  userGet,
  userPut,
  userSettingsPut,
  setToken,
  lotsGet,
  programGet,
  programsNewsGet,
  callforfundsGet,
  newPasswordPost,
  uploadPost,
  documentsGet,
  fileGet,
  fileGetByPath,
  workflowGet,
};

import React from 'react';
import styled from 'styled-components';
import {
  PageHeader,
  WrapperCard,
  H4,
  Row,
  IconRoundBackground,
} from '../Components';
import { COLORS } from '../config';

const HH4 = styled(H4)`
  margin: 8px;
  margin-top: 32px;
`;
const Span = styled.p`
  font-size: 14px;
  margin: 8px;
`;

const ContactCardPhoto = ({ image, name, phone, email, title }) => {
  return (
    <WrapperCard row={false} style={{ padding: 16 }}>
      <Row>
        <Row>
          <img
            src={image}
            alt='photo_contact'
            style={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          <div>
            <H4>{name}</H4>
            <span style={{ fontSize: 14, color: COLORS.secondary }}>
              {title}
            </span>
          </div>
        </Row>
        <div>
          <a
            href={`tel:${phone}`}
            style={{ textDecoration: 'none', color: COLORS.primary }}
          >
            <IconRoundBackground style={{ marginBottom: 8 }}>
              <span
                className='icon-dbi-pic-_telephoner'
                style={{ fontSize: 18 }}
              />
            </IconRoundBackground>
          </a>
          <a
            href={`mailto:${email}`}
            style={{ textDecoration: 'none', color: COLORS.primary }}
          >
            <IconRoundBackground>
              <span
                className='icon-dbi-pic-_Message'
                style={{ fontSize: 18, textDecoration: 'none' }}
              />
            </IconRoundBackground>
          </a>
        </div>
      </Row>
      <div>
        {phone}
        <br />
        {email}
      </div>
    </WrapperCard>
  );
};

const Contacts = ({ lot }) => {
  const [contacts, setContacts] = React.useState([]);
  React.useEffect(() => {
    if (!lot?.program?.contacts) return;
    console.log(lot?.program?.contacts);
    const mappedContacts = lot?.program?.contacts?.map((cont) => {
      return {
        title: cont.contactsPrograms.type === "technique" ? "Technique" : (cont.contactsPrograms.type === "commercial" ? "Relation clientèle" : "Aide à la vente"),
        subtitle:cont.contactsPrograms.type === "technique" ? "Pour quelles raisons le contacter : questions techniques concernant votre logement, travaux modificatifs et choix, informations chantier, SAV" : (cont.contactsPrograms.type === "commercial" ? "Pour quelles raisons le contacter : suivi administratif de votre dossier, gestion des appels de fonds, questions notariées, informations chantier" : "Pour quelles raisons le contacter : Pour toutes questions liées à son domaine"),
        contact: {
          image: cont.image || '/images/contact_default.svg',
          name: cont.firstname + ' ' + cont.lastname,
          title: cont.title,
          email: cont.email,
          phone: cont.phone,
        },
      };
    });
    setContacts(mappedContacts);
  }, [lot]);

  return (
    <>
      <PageHeader title={'Contacts'} />
      {!contacts || contacts.length === 0 ? (
        <p style={{ marginLeft: 16, fontWeight: 300, fontStyle: 'italic' }}>
          Aucun contact à afficher pour le moment.
        </p>
      ) : (
        contacts.map((card, i) => (
          <div key={'contact_page_card' + i}>
            <HH4>{card.title}</HH4>
            <Span>{card.subtitle}</Span>
            <ContactCardPhoto
              image={card.contact.image || '/images/contact_default.svg'}
              name={card.contact.name}
              title={card.contact.title}
              email={card.contact.email}
              phone={card.contact.phone}
            />
          </div>
        ))
      )}
    </>
  );
};

export { Contacts };

import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { COLORS, commonStyle } from '../../config';

const anim = {
  initial: { width: '20px' },
  in: { width: '200px' },
  out: { width: '10px' },
};

const Motion = styled(motion.div)`
  padding: 8px;
  margin: 8px;
  // height: 30px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.classic};
  border-radius: ${commonStyle.borderRadius}px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const createContainer = () => {
  const portalId = 'message';
  let element = document.getElementById(portalId);

  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', portalId);
  element.setAttribute(
    'style',
    `position: fixed;
    top: 16px;
    left: 16px;`
  );
  // element.className = styles.container;
  document.body.appendChild(element);
  return element;
};

const container = createContainer();

const Notification = ({
  duration = 3000,
  children,
  onDelete,
  type = 'info',
  id,
}) => {
  const [deleteMe, setDeleteMe] = React.useState(false);

  const controls = useAnimation();

  useEffect(() => {
    if (duration) {
      const timeoutId = setTimeout(() => setDeleteMe(true), duration);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [duration]);

  useEffect(() => {
    controls.start('in');
    return () => {
      controls.start('out');
    };
  }, []);

  useEffect(() => {
    if (deleteMe) onDelete();
  }, [deleteMe]);

  return ReactDOM.createPortal(
    <Motion
      layout
      key={id}
      animate={controls}
      transition={{ ease: 'easeOut', duration: 0.3 }}
      initial='initial'
      // aniamte='in'
      // exit='out'
      variants={anim}
      style={{
        backgroundColor: type === 'error' ? COLORS.error : COLORS.primary,
      }}
      onClick={onDelete}
    >
      {children}
    </Motion>,
    container
  );
};

export { Notification, createContainer };

import React from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../config';

const BMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 650px;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 64px;
  align-items: center;
  justify-content: space-around;
  background-color: ${COLORS.classic};
  border-radius: 10px 10px 0 0;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.16);
`;

const Icone = styled.span`
  padding: 16px;
  font-size: 18px;
  color: ${(props) => (props.isActive ? COLORS.secondary : COLORS.primary)};
`;

const MenuItem = styled(Link)`
  text-decoration: none;
`;

const BottomMenu = () => {
  let { path } = useRouteMatch();
  let { residenceId } = useParams();

  return (
    <BMenu className='bottomMenuMobile'>
      <MenuItem to={'/' + residenceId}>
        <Icone isActive={path === '/:residenceId'} className='icon-dbi-pic-'>
          
        </Icone>
      </MenuItem>
      <MenuItem to={'/documents/' + residenceId}>
        <Icone
          isActive={path.search('/documents/') !== -1}
          className='icon-dbi-pic-_menu-document'
        >
        </Icone>
      </MenuItem>
      <MenuItem to={'/compte/' + residenceId}>
        <Icone
          className='icon-dbi-pic-_menu-compte'
        >
        </Icone>
      </MenuItem>
    </BMenu>
  );
};

export { BottomMenu };

import React, { useState } from 'react';
import { COLORS, commonStyle } from '../config';
import { WrapperCard, H4 } from '.';
import { IconRoundBackground, Modal, Button } from '.';
import { Document, Page, pdfjs } from 'react-pdf';
import { fileGet } from '../API';
import { message } from './Notify';
import {ArkaIframe} from "./ArkaIframe";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const buttonStyle = { width: 35, height: 35, padding: 0 };

const ArkaCard = ({
                          title = 'Appartement 3D',
                          subtitle = 'Configurateur de logement',
                          style,
                          lotId,
                          programId,
                          url = 'multi.pdf',
                      }) => {
    const [openModal, setOpenModal] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setscale] = useState(1);
    const [blob, setBlob] = useState(null);

    const handleView = async () => {
        try {
            setBlob(url);
            setOpenModal(true);
        } catch (error) {
            console.error(error);
            message.error("Echec d'affichage du document");
        }
    };

    return (
        <WrapperCard style={{ ...style, justifyContent: 'space-between' }} shadow>
            <div
                style={{
                    margin: 8, width: '60%', color:'#FFFFFF'
                }}
            >
                <H4 style={{ wordBreak: 'break-all',color:'#FFFFFF' }}>
                    {title}
                </H4>
                <span style={{ fontSize: 12 }}>{subtitle}</span>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconRoundBackground onClick={handleView}>
          <span
              className={`icon-dbi-pic-_Voir`}
              style={{
                  fontSize: 12,
                  color: '#FFFFFF',
                  backgroundColor: 'rgb(224, 76, 56) !important'
              }}
          />
                </IconRoundBackground>
            </div>
            <Modal
                visible={openModal}
                handleCancel={() => setOpenModal(false)}
                style={{ height: '100%' }}
            >
                <div
                    style={{
                        flexDirection: 'column',
                        padding: 8,
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            flex: 0,
                            border: `1px solid ${COLORS.disabled}`,
                            borderRadius: commonStyle.borderRadius,
                            overflow: 'scroll',
                            height: '100%',
                            padding: 4,
                        }}
                    >
                        <ArkaIframe url={<iframe src={url} />}></ArkaIframe>
                    </div>
                </div>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 32,
                        left: 0,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginBottom: 8,
                        marginRight: 32,
                        paddingTop: 16,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 8,
                        }}
                    >
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 8,
                        }}
                    >
                    </div>
                </div>
            </Modal>
        </WrapperCard>
    );
};

export { ArkaCard };

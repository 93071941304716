import React from 'react';
import { WrapperCard } from './WrapperCard';
const MeetingCard = ({ date, description, style, children }) => {
  return (
    <WrapperCard style={{ alignItems: 'center', ...style }}>
      <span
        className='icon-dbi-pic-_Rdv'
        style={{ fontSize: 18, marginRight: 16, marginLeft: 8, marginTop: 0 }}
      />
      <div
        style={{
          fontSize: 12,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ margin: 0 }}>{children}</h3>
        {date ? (
          <p style={{ margin: 0 }}>
            {date}
          </p>
        ) : (
          <p style={{ margin: 0, fontStyle: 'italic' }}>Date à définir.</p>
        )}
        {description && (
          <>
            <br />
            {description}
          </>
        )}
      </div>
    </WrapperCard>
  );
};

export { MeetingCard };
import { AnimateSharedLayout, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { AppelDrawer, H4, PageHeader, WrapperCard, TR } from '../Components';
import { COLORS } from '../config';
import { callforfundsGet } from '../API';

const stepNames = [
	"", //0
	/*** // 20240129 // Les étapes de l'échéancier sont récupérées via les données de l'API
	"Signature de l'Acte de Vente et démarrage effectif des travaux", //1
	"Achèvement des fondations", //2
	"Achèvement du plancher bas du premier étage", //3
	"Mise hors d'eau", //4
	"Achèvement du cloisonnement", //5
	"Achèvement", //6
	"Achèvement des travaux de cloisonnement du bâtiment, remise des clés/livraison et fourniture des pièces, documents dont la liste figure à l'article 9.6.1", //7
	"Levée des réserves et fourniture des pièces, documents dont la liste figure à l'article 9.6.2", //8
	"Non contestation de la conformité et fourniture des pièces, documents dont la liste figure à l'article 10.6.3" //9
	***/
];

const AppelFonds = ({ lot, updateLot }) => {
		const [appel, setappel] = useState({});
		const [activeCard, setActiveCard] = useState(0);
		const [lotId, setLotId] = useState(null);
		const [echeance, setEcheance] = useState(null);
		const [call, setCall] = useState(null);
		const [paid, setPaid] = useState(null);
		let maxis = [];

		const syncData = async () => {
			try {
				if (!lot || !lot.id) return;
				const appel = await callforfundsGet({ lotId: lot?.id });
				
				// 20240129 // Etapes de l'échéancier
				for (const [k, it] of Object.entries(appel)) {
					stepNames[k] = it['type'];
					console.log (it);
				}
				console.log (stepNames);
				
				calculateData(appel);
				setappel(appel);
				updateLot({ ...lot, appel: appel });
			} catch (error) {
				console.error(error);
				calculateData(lot?.appel);
				setappel(lot?.appel);
			}
		};

		const calculateData = (appel) => {
			let data = { ...appel };
			setEcheance(
				Object.values(data)
				.filter((v) => v['3'] !== null || v['3'] > 0 || v['3'] !== '0')
				.reduce((prev, val) => prev + Number(val['3'] || 0), 0)
			);
			setCall(
				Object.values(data)
				.filter((v) => v['4'] !== null || v['4'] > 0 || v['4'] !== '0')
				.reduce((prev, val) => prev + Number(val['4'] || 0), 0)
			);
			setPaid(
				Object.values(data)
				.filter((v) => v['7'] !== null || v['7'] > 0 || v['7'] !== '0')
				.reduce((prev, val) => prev + Number(val['7'] || 0), 0)
			);
		};

		useEffect(() => {
			syncData();
		}, [lotId]);

		useEffect(() => {
			if (lot && lot.id !== lotId) setLotId(lot.id);
		}, [lot]);

		useEffect(() => {
			setActiveCard(maxis[0]);
		}, [appel]);

		return (
	<>
	  <PageHeader title={'Échéancier des appels de fonds'} />
	  <H4 style={{ marginTop: 16, marginBottom: 8, marginLeft: 8 }}>
		Résumé :
	  </H4>
	  <WrapperCard row={false}>
		<H4
		  style={{
			paddingTop: 4,
			paddingBottom: 8,
			borderBottom: `1px solid ${COLORS.secondary}`,
			textAlign: 'center',
		  }}
		>
		  TOTAL
		</H4>
		<table style={{ width: '100%', borderCollapse: 'collapse' }}>
		  <tbody>
			<TR>
			  <td>Montant échéance TTC : </td>
			  <td>{Number(echeance || 0).toLocaleString('fr-FR', {style:'currency', currency:'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
			</TR>
			<TR>
			  <td>Montant à verser TTC : </td>
			  <td>{Number(call || 0).toLocaleString('fr-FR', {style:'currency', currency:'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
			</TR>
			<TR>
			  <td>Montant déjà versé TTC : </td>
			  <td>{Number(paid || 0).toLocaleString('fr-FR', {style:'currency', currency:'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
			</TR>
		  </tbody>
		</table>
	  </WrapperCard>
	  <AnimateSharedLayout>
	   <motion.div layout>
		 <>
		   {Array.from({ length: stepNames.length }, (_, i) => i).map((i) => {
				var maxi = Number(Math.round(appel?.[String(i)]?.['7'] + 1)) >= Number(Math.round(appel?.[String(i)]?.['3'])) ? i : null;
				if (maxi !== null) maxis.push(maxi);
				maxis.sort((a, b) => b - a);
			})}

			{Array.from({ length: stepNames.length }, (_, i) => i).map((i) => {
				if ((i !== 0) && (appel?.[String(i)]?.['1'])) {
			 return (
			   <AppelDrawer
				 key={'appel_card' + i}
				 passed={
				   Number(Math.round(appel?.[String(i)]?.['7'] + 1) >= Math.round(Number(appel?.[String(i)]?.['3'])))
				 } // Bleu
				 toPay={i === maxis[0] ? true : false} // Rouge
				 title={stepNames[i]}
				 active={i === activeCard}
				 ADF={appel?.[String(i)]?.['1']}
				 cumul={appel?.[String(i)]?.['2']}
				 echeance={appel?.[String(i)]?.['3']}
				 call={appel?.[String(i)]?.['4']}
				 date={appel?.[String(i)]?.['5']}
				 datePaid={appel?.[String(i)]?.['6']}
				 paid={appel?.[String(i)]?.['7']}
				 link={appel?.[String(i)]?.['invoice']}
				 handleActive={() => setActiveCard((s) => (s === i ? null : i))}
			   ></AppelDrawer>
			 );
		 }
		   })}
		 </>
	   </motion.div>


	  </AnimateSharedLayout>
	</>
  );
};

export { AppelFonds };
import React, { useState } from 'react';
import { COLORS, commonStyle } from '../config';
import { WrapperCard, H4 } from '.';
import { IconRoundBackground, Modal, Button } from '.';
import { Document, Page, pdfjs } from 'react-pdf';
import { fileGet } from '../API';
import { message } from './Notify';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const buttonStyle = { width: 35, height: 35, padding: 0 };

const DocumentCard = ({
  title = 'Default Document',
  subtitle = 'default subtitle for Document card',
  style,
  lotId,
  programId,
  filename = 'multi.pdf', //'https://www.fci-immobilier.com/wp-content/uploads/Guide%20du%20negociateur%20immobilier.pdf' //'https://www.dunod.com/sites/default/files/atoms/files/9782100789665/Feuilletage.pdf'
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setscale] = useState(1);
  const [blob, setBlob] = useState(null);

  const handleDownload = async () => {
    try {
      await fileGet({ lotId, programId, filename: filename, download: true });
    } catch (error) {
      console.error(error);
    }
  };

  const handleView = async () => {
    try {
      const doc = await fileGet({
        lotId,
        programId,
        filename: filename,
        download: false,
      });
      setBlob(doc.data);
      setOpenModal(true);
    } catch (error) {
      console.error(error);
      message.error("Echec d'affichage du document");
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <WrapperCard style={{ ...style, justifyContent: 'space-between' }} shadow>
      <div
        style={{
          margin: 8, width: '60%'
        }}
      >
        <H4 style={{ wordBreak: 'break-all' }}>
          {title}
        </H4>
        <span style={{ fontSize: 12 }}>{subtitle}</span>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconRoundBackground onClick={handleView}>
          <span
            className={`icon-dbi-pic-_Voir`}
            style={{
              fontSize: 12,
              backgroundColor: COLORS.classic,
            }}
          />
        </IconRoundBackground>
        <IconRoundBackground style={{ marginLeft: 8 }} onClick={handleDownload}>
          <span
            className={`icon-dbi-pic-_telecharger`}
            style={{
              fontSize: 24,
              backgroundColor: COLORS.classic,
              borderRadius: 12,
            }}
          />
        </IconRoundBackground>
      </div>
      <Modal
        visible={openModal}
        handleCancel={() => setOpenModal(false)}
        style={{ height: '100%' }}
      >
        <div
          style={{
            flexDirection: 'column',
            padding: 8,
            height: '100%',
          }}
        >
          <div
            style={{
              flex: 0,
              border: `1px solid ${COLORS.disabled}`,
              borderRadius: commonStyle.borderRadius,
              overflow: 'scroll',
              height: '100%',
              padding: 4,
            }}
          >
            {blob && filename.search(/.pdf$/) !== -1 ? (
              <Document
                file={blob}
                // renderMode='svg'
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={scale}
                  // width={'100%'}
                />
              </Document>
            ) : (
              <img
                src={blob}
                alt="Echec d'affichage de l'image, essayez de la télécharger."
                width={`${scale * 100}%`}
                height={'auto'}
              />
            )}
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 32,
            left: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginBottom: 8,
            marginRight: 32,
            paddingTop: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <Button
              primary
              disabled={scale <= 0.19}
              onClick={() => (scale > 0.1 ? setscale((sc) => sc - 0.1) : null)}
              style={buttonStyle}
            >
              -
            </Button>
            <h4 style={{ margin: 4 }}>{Math.round(scale * 100)}%</h4>
            <Button
              primary
              disabled={scale >= 4}
              onClick={() => (scale < 4 ? setscale((sc) => sc + 0.1) : null)}
              style={buttonStyle}
            >
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <Button
              primary
              disabled={pageNumber === 1}
              onClick={() =>
                pageNumber > 1 ? setPageNumber((st) => st - 1) : null
              }
              style={buttonStyle}
            >
              -
            </Button>
            <h4 style={{ margin: 4 }}>
              {pageNumber}/{numPages}
            </h4>
            <Button
              primary
              disabled={pageNumber === numPages}
              onClick={() =>
                pageNumber < numPages ? setPageNumber((st) => st + 1) : null
              }
              style={buttonStyle}
            >
              +
            </Button>
          </div>
        </div>
      </Modal>
    </WrapperCard>
  );
};

export { DocumentCard };

const { default: styled } = require('styled-components');
const { COLORS, commonStyle } = require('../config');

const H1 = styled.h1`
  color: ${COLORS.classic};
  font-size: 28px;
  font-weight: 300;
`;

const H2 = styled.h2`
  color: ${COLORS.classic};
  font-weight: 800;
`;

const Input = styled.input`
  border-radius: ${commonStyle.borderRadius * 2}px;
  padding: 8px;
  padding-left: 16px;
  margin: 8px;
  background: transparent;
  color: ${COLORS.classic};
  border: 1px solid ${COLORS.classic};
  outline: none;
  ::placeholder {
    font-size: 0.9em;
    font-style: italic;
  }
`;

const UL = styled.ul`
  list-style: none; /* Remove default bullets */
  margin-top: 4px;
  margin-bottom: 4px;
`;

const LI = styled.li`
  color: ${COLORS.text};
  font-size: 12px;
  line-height: 21px;
  &::before {
    background-color: ${COLORS.secondary};
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    margin-left: -2em;
    margin-right: 12px;
  }
`;

const H4 = styled.h4`
  margin: 0;
  color: ${COLORS.primary};
`;

const IconRoundBackground = styled.div`
  border-radius: 20px;
  border: 1px solid ${COLORS.disabled};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: ${COLORS.primary};
  margin-top: 8px;
  font-weight: ${(props) => (props.over ? 800 : 400)};
`;

const List = styled.ul`
  list-style: none;
  padding-left: 8px;
  & li:first-child {
    border-top: 1px solid ${COLORS.disabled};
  }
`;
const ListItem = styled.li`
  border-bottom: 1px solid ${COLORS.disabled};
  padding: 8px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

List.Item = ({ label, style, children }) => {
  return (
    <ListItem style={style}>
      {label && <h6 style={{ margin: 0, fontWeight: 400 }}>{label}</h6>}
      {children}
    </ListItem>
  );
};

const TR = styled.tr`
  line-height: 24px;
  :nth-child(2n) {
    background-color: ${COLORS.background};
  }
  & > :nth-child(2n + 1) {
    text-align: right;
    width: 220px;
  }
  & > :nth-child(2n) {
    font-weight: 700;
  }
`;

export {
  H1,
  H2,
  H4,
  Input,
  UL,
  LI,
  IconRoundBackground,
  Subtitle,
  List,
  Row,
  TR,
};

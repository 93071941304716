import React, { useEffect, useLayoutEffect } from 'react';
import { PageHeader } from '../Components';
import Article from '../Components/Article';

const Journal = ({ lot }) => {
  
  const [news, setNews] = React.useState([]);
  const [width, setWidth] = React.useState(100);

  const sizeRef = React.useRef(null);

  useEffect(() => {
    if (!lot) return;
    const syncData = async () =>  {
      try {
        setNews(lot.programsnews || []);
      } catch (error) {
        console.error(error);
      }
    };
    syncData();
  }, [lot]);

	/*
  const syncData = async () =>  {
    try {
      setNews(JSON.parse(lot.program?.news) || []);
    } catch (error) {
      console.error(error);
    }
  };
  */

  useLayoutEffect(() => {
    if (sizeRef.current) {
      setWidth(sizeRef.current.clientWidth); // We need width after page has layout to give it to video player width
    }
  }, [sizeRef]);

  return (
    <div ref={sizeRef}>
      <PageHeader title={'Journal du chantier'} />
      {
        news.length === 0
          ? 'Pas encore de nouvelles du chantier, revenez bientôt !'
          : news.map((article, i) => (
              <Article article={article} key={i} width={width} />
            ))
      }
    </div>
  );
};

export { Journal };

import React from 'react';
import styled from 'styled-components';
import { H2 } from '.';
import { COLORS } from '../config';

const MDiv = styled.div`
  display: block;
  margin-left: 8px;
`;

const MP = styled.p`
  margin-top: 0px;
  font-size: 12px;
  font-weight: lighter;
  color: ${COLORS.primary};
`;

const PageHeader = ({ title, style, subtitle }) => {
  return (
    <MDiv>
      <H2
        style={{
          color: COLORS.primary,
          marginBottom: 0,
          fontSize: 20,
        }}
      >
        {title}
      </H2>
      {subtitle && <MP>{subtitle}</MP>}
    </MDiv>
  );
};

export { PageHeader };

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PageHeader,
  TravauxCard,
  TravauxWorkflowCard,
  WorkflowItem,
} from '../Components';

const Travaux = ({ lot }) => {
  const [workflow, setWorkflow] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setendDate] = useState(0);
  const [maxWorkflow, setMaxWorkflow] = useState(0);
  let { residenceId } = useParams();
  useEffect(() => {
    if (lot?.program?.workflow) {
      let startDate = {};
      let endDate = {};
      let max = lot.program.workflow.reduce((max, val) => {
        if (val.workflowTypeId > max) return val.workflowTypeId;
        else return max;
      }, 0);
      setMaxWorkflow(max);
      for (let i = 1; i <= max; i++) {
          startDate[i] = lot?.program?.workflow.find((wrk) => wrk.workflowTypeId === i)
                    ?.startDate || "";
          endDate[i] = lot?.program?.workflow.find((wrk) => wrk.workflowTypeId === i)
              ?.endDate || "";
      }
        setStartDate(startDate);
        setendDate(endDate);
    }
  }, [lot]);
  return (
    <>
      <PageHeader title={'Avancée des travaux'} />

      {/** Worflow line ... */}
        {/* <WorkflowItem
        title={'Réservation'}
        icon={'_tr-reservation'}
        startDate={startDate['2']}
        endDate={endDate['2']}
        active={startDate['2']}
      /> */}
      
      {lot?.program?.workflow
        .map((workflow) => (
          <>
				      {workflow.workflow_type.state === "Période de TMA" ? ( <TravauxWorkflowCard
				        style={{ marginTop: 16 }}
				        title={workflow.workflow_type.state}
				        subtitle={'C’est le moment d’apporter des travaux modificatifs à votre logement, si vous le souhaitez.'
				        }
				        buttonAlert={
				            startDate['3']
				            ? undefined
				            : {
				                title: 'Faire une demande',
				                link: `/tma/${residenceId}`,
				              }
				        }
				        button={{
				          title: 'Voir mes demandes',
				          link: `/tma/${residenceId}`,
				        }}
				        active={workflow.startDate}
				        over={workflow.startDate || maxWorkflow > 2} //FIXME : Unknown condition for when TMA is Over => Deadline TMA Date ?
				      /> ) : ( 
				      	<>
			          {workflow.workflow_type.state === "Choix des prestations" ? ( <TravauxWorkflowCard
						        style={{ marginTop: 2 }}
						        title={workflow.workflow_type.state}
						        subtitle={
						          'Choisissez votre décoration : vos sols, vos matières, vos couleurs…'
						        }
						        button={{
						          title: 'Voir le catalogue',
						          link: `/prestations/${residenceId}`,
						        }}
						        active={workflow.startDate}
						        over={lot?.choicePrestationsDone || maxWorkflow > 7} 
									/> ) : ( <WorkflowItem
						        title={workflow.workflow_type.state}
						        icon={workflow.workflow_type.icon}
						        startDate={workflow.startDate}
						        endDate={workflow.endDate} 
						        active={workflow.startDate}
						     /> )}
						     </>
			     		) }
			    </> 
        ))}
      
      {/***
      <WorkflowItem
        title={'Démarrage des travaux'}
        icon={'_tr-demarrage-travaux'}
        startDate={startDate['3']}
        endDate={endDate['3']}
        active={startDate['3']}
      />
      <WorkflowItem
        title={'Achèvement des fondations'}
        icon={'_tr-fondations'}
        startDate={startDate['4']}
        endDate={endDate['4']}
        active={startDate['4']}
      />
      <WorkflowItem
        title={'Fin des gros oeuvres'}
        icon={'_tr-fin-gros-oeuvre'}
        startDate={startDate['5']}
        endDate={endDate['5']}
        active={startDate['5']}
      />
      <TravauxWorkflowCard
        style={{ marginTop: 2 }}
        title={'Choix des prestations'}
        subtitle={
          'Choisissez votre décoration : vos sols, vos matières, vos couleurs…'
        }
        button={{
          title: 'Voir le catalogue',
          link: `/prestations/${residenceId}`,
        }}
        active={startDate['6']}
        over={lot?.choicePrestationsDone || maxWorkflow > 7} // FIXME : unknown info ??
      />
      <WorkflowItem
        title={'Achèvement des cloisons'}
        icon={'_tr-fin-cloisons'}
        startDate={startDate['7']}
        endDate={endDate['7']}
        active={startDate['7']}
      />
        <WorkflowItem
            title={'Visite des cloisons achevées'}
            icon={'_tr-fin-cloisons'}
            startDate={startDate['8']}
            endDate={endDate['8']}
            active={startDate['8']}
        />
      <WorkflowItem
        title={'Achèvement des travaux'}
        icon={'_tr-fin-travaux'}
        startDate={startDate['9']}
        endDate={endDate['9']}
        active={startDate['9']}
      />
        <WorkflowItem
            title={'Achèvement des travaux'}
            icon={'_tr-fin-travaux'}
            startDate={startDate['9']}
            endDate={endDate['9']}
            active={startDate['9']}
        />
        <WorkflowItem
            title={'Visite de pré-livraison'}
            icon={'_tr-fin-travaux'}
            startDate={startDate['10']}
            endDate={endDate['10']}
            active={startDate['10']}
        />
      <WorkflowItem
        title={'Livraion'}
        icon={'_tr-livraison'}
        startDate={startDate['11']}
        endDate={endDate['11']}
        active={startDate['11']}
      />
        <WorkflowItem
            title={'Levée des réserves'}
            icon={'_tr-livraison'}
            startDate={startDate['12']}
            endDate={endDate['12']}
            active={startDate['12']}
        />
      <WorkflowItem
        title={'Service après-vente'}
        icon={'_tr-sav'}
        startDate={startDate['13']}
        endDate={endDate['13']}
        active={startDate['13']}
      />
      ***/}
      
        {/*<TravauxWorkflowCard
        style={{ marginTop: 2, marginBottom: 24 }}
        title={'SAV et garanties'}
        alert={"Jusqu'à 10 ans"}
        button={{
          title: 'Faire une demande',
          link: `mailto:${lot?.contacts?.sav?.email}`,
        }}
        active={startDate['13']}
        over={lot?.program?.choiceDate} // FIXME : Unknown logic and info ??
      />
      <TravauxCard chantier={true} /> */}
    </>
  );
};

export { Travaux };

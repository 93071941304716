import React, { useEffect, useState } from 'react';
import { InstallPrompt, Loader, Refresher } from './Components';
import { Login, Home } from './Pages';
import { COLORS } from './config';
import {
  setToken,
  lotsGet,
  programGet,
  programsNewsGet,
  callforfundsGet,
  documentsGet,
  userGet,
  fileGet,
  workflowGet,
} from './API';

function App() {
  const [user, setUser] = useState({ connected: false });
  const [lots, setLots] = useState([]);
  const [refreshStart, setRefreshStart] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    // localStorage.clear(); // DEV : use to reset app
    const LS = localStorage.getItem('user');
    let tmpUser = {};
    let tmpLots = [];

    if (LS) {
      tmpUser = JSON.parse(LS);
      const LSlots = localStorage.getItem('lots');
      if (LSlots) tmpLots = JSON.parse(LSlots);
    }
    setUser(tmpUser || { connected: false });
    setLots(tmpLots);
    const token = localStorage.getItem('token');
    if (tmpUser && token) handleLogin({ user: tmpUser, accessToken: token });
  }, []);

  const handleDisconnect = () => {
    localStorage.clear(); // We flush localstorage to remove all info, user, residences etc ...
    setUser(null);
    setLots([]);
  };

  const handleLogin = async (login) => {
    try {
      setUser({ ...login.user, connected: true });
      if (login.accessToken) {
        await setToken(login.accessToken);
        localStorage.setItem('token', login.accessToken);
      }
      localStorage.setItem(
        'user',
        JSON.stringify({ ...login.user, connected: true })
      );
      syncData(login);
    } catch (error) {
      console.error(error);
    }
  };

  const syncData = async (login) => {
    try {
      setRefreshing(true);
      if (!login || !login.user) {
        let userFetch = await userGet(user);
        updateUser(userFetch);
      }
      let lots = await lotsGet({ id: login?.user?.id || user.id });
      for (const lot of lots) {
        const program = await programGet({ programId: lot.programId });
        lot.program = program;
        const programsnews = await programsNewsGet({ programId: lot.programId });
        lot.programsnews = programsnews;
        /*const appel = await callforfundsGet({ lotId: lot.id });
        lot.appel = appel;*/
        const workflow = await workflowGet({ programId: program.id });
        lot.program.workflow = workflow;
        const documents = await documentsGet({
          reservationId: lot.reservationId,
          programId: program.id,
        });
        localStorage.setItem('reservationId', lot.reservationId);
        lot.documents = documents;
        const visuelProg = lot.documents?.filter(
          (doc) => doc.objectsTypeId === 10
        )?.[0]?.filename;
        if (visuelProg) {
          const doc = await fileGet({
            lotId: lot.id,
            programId: program.id,
            filename: visuelProg,
            download: false,
          });
          // setBlob(doc.data);
          lot.program.visuel = doc.data;
        } else lot.program.visuel = '/images/default_building.svg';
      }

      setLots(lots);
      localStorage.setItem('lots', JSON.stringify(lots));
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        //FIXME : Timeout for Demo, remove
        setRefreshing(false);
      }, 1000);
    }
  };

  const updateLot = (lot) => {
    if (!lot || !lot.id) return;
    let tmpLots = [...lots];
    let lotIndex = lots.findIndex((l) => l.id === lot.id);
    if (!lot.program) lot.program = lots[lotIndex].program;
    if (!lot.appel) lot.appel = lots[lotIndex].appel;
    tmpLots.splice(lotIndex, 1, lot);
    setLots(tmpLots);
    localStorage.setItem('lots', JSON.stringify(tmpLots));
  };

  const updateUser = (updatedUser) => {
    setUser({ ...updatedUser, connected: true });
    localStorage.setItem(
      'user',
      JSON.stringify({
        ...updatedUser,
        connected: true,
      })
    );
  };

  const handleTouchMove = (e) => {
    if (!refreshStart || refreshing) return;
    const y = e.touches[0].pageY;
    const touchLength = e.touches[0].pageY - refreshStart;
    if (
      document.scrollingElement.scrollTop <= 0 &&
      y > refreshStart &&
      touchLength > 80
    ) {
      syncData();
      setRefreshStart(null);
    }
  };

  return (
    <>
      <Loader />
      <div
        style={{ height: '100%', backgroundColor: COLORS.background }}
        onTouchMove={handleTouchMove}
        onTouchStart={(e) => setRefreshStart(e.touches[0].pageY)}
        className='App'
      >
        <Refresher active={refreshing} />
        {user?.connected && lots ? (
          <Home
            user={user}
            lots={lots}
            disconnect={handleDisconnect}
            updateLot={updateLot}
            updateUser={updateUser}
            refresh={syncData}
          />
        ) : (
          <Login handleLogin={handleLogin} />
        )}
        <InstallPrompt />
      </div>
    </>
  );
}

export default App;

import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Button,
  H1,
  H2,
  LoginHeaderWrapper,
  ResidenceCard,
} from '../Components';
import { COLORS } from '../config';

const Residences = ({ disconnect, user, lots }) => {
  const history = useHistory();
  const handleDisconnect = () => {
    console.log('DISCONNECT');
    disconnect();
    history.push('/');
  };

  return lots.length === 1 ? (
    <Redirect to={'/' + lots[0].id} />
  ) : (
    <div
      style={{
        height: '100%',
        width: '100%',
        textAlign: 'center',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <LoginHeaderWrapper style={{ height: 500 }}>
        <Button
          onClick={handleDisconnect}
          style={{
            width: 10,
            height: 10,
            position: 'absolute',
            left: 32,
            top: 32,
            padding: 0,
          }}
        >
          <span
            className='icon-dbi-pic-_Disconnect'
            style={{
              fontSize: 28,
              color: COLORS.primary,
              backgroundColor: COLORS.classic,
              borderRadius: '50%',
            }}
          />
        </Button>
        <H1 style={{ marginTop: 0 }}>ESPACE CLIENT</H1>
        <H2 style={{ marginTop: 64, marginBottom: 4 }}>
          Bonjour M. {user.lastname}
        </H2>
        <p style={{ marginTop: 0, color: COLORS.classic, marginBottom: 48 }}>
          Choisissez votre résidence
        </p>
        {lots.map((lot, i) => (
          <ResidenceCard
            key={'residence_card_' + i}
            {...lot}
           img={lot.program?.visuel}
          />
        ))}
      </LoginHeaderWrapper>
    </div>
  );
};

export { Residences };

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { COLORS, commonStyle } from '../config';
import { message } from '../Components/Notify';
import { uploadPost } from '../API';

const UploadDiv = styled.div`
  border-radius: ${commonStyle.borderRadius}px;
  background-color: ${COLORS.disabled};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110px;
  cursor: pointer;
`;
const ProgressBox = styled.div`
  height: 4px;
  width: calc(
    100% -
      ${(props) =>
        props.style && props.style.margin
          ? `${props.style.margin}px`
          : `${props.style.marginLeft + props.style.marginRight}px`}
  );
  border-radius: ${commonStyle.borderRadius}px;
  background-color: grey;
`;
const Progress = (props) => (
  <ProgressBox style={props.style}>
    <div
      style={{
        width: `${props.percent}%`,
        backgroundColor: COLORS.success,
        height: 4,
      }}
    ></div>
  </ProgressBox>
);

const UploadZone = ({
  icon = '+',
  title = 'Choisissez un fichier',
  style,
  lot,
  typeId,
  refresh,
}) => {
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const fileRef = useRef();

  const handleUpload = () => {
    fileRef.current.click();
  };

  const handleFileDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFileList([e.target.files.item(0)]);
    sendUpload(e.target.files[0]);
  };

  const sendUpload = async (file) => {
    try {
      const uploaded = await uploadPost({
        file,
        lotId: lot.id,
        programId: lot.program.id,
        reservationId: lot.reservationId,
        typeId,
        description: 'Upload', // 'Choix de prestation',
        callback: setUploadProgress,
      });
      if (!uploaded) throw new Error("Erreur d'upload, réessayez.");
      else {
        message.success('Fichier envoyé.', 5000);
        setUploadProgress(null);
        setFileList([]);
        refresh();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UploadDiv style={style} onClick={handleUpload}>
      <input
        ref={fileRef}
        type='file'
        id='upload'
        name='uploadFile'
        accept='image/png,image/jpeg,application/pdf,image/x-eps'
        style={{ display: 'none' }}
        onChange={handleFileDrop}
      ></input>
      {!fileList ||
        (fileList?.length === 0 && (
          <div
            style={{
              height: 28,
              width: 28,
              borderRadius: 14,
              backgroundColor: COLORS.background,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 34,
              fontWeight: 300,
              lineHeight: 0,
            }}
          >
            {icon}
          </div>
        ))}
      {!fileList || fileList?.length === 0 ? (
        <div style={{ fontSize: 12, lineHeight: 2 }}>{title}</div>
      ) : (
        fileList.map((file) => (
          <div
            key={file.name}
            style={{
              margin: 8,
              border: `1px solid ${COLORS.secondary}`,
              borderRadius: commonStyle.borderRadius,
              padding: 8,
              maxWidth: 'calc(100% - 32px)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {file.name}
          </div>
        ))
      )}
      {typeof uploadProgress === 'number' && (
        <Progress
          percent={uploadProgress}
          style={{ marginRight: 16, marginLeft: 16 }}
        />
      )}
    </UploadDiv>
  );
};

export { UploadZone };

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, ErrorBox, PageHeader, Select } from '../Components';
import { H4, Input, List, Row } from '../Components/H1';
import { COLORS, commonStyle } from '../config';
import { userGet, userPut, newPasswordPost, userSettingsPut } from '../API';
import { message } from '../Components/Notify';
import {useHistory} from "react-router-dom";

const IconEdit = styled.span`
  font-size: 12px;
  background-color: ${COLORS.classic};
  padding: 8px;
  border-radius: 50%;
  margin-top: -12px;
  cursor: pointer;
  width: 14px;
  height: 14px;
`;

const EditInput = styled.input`
  font-size: 15px;
  color: ${COLORS.primary};
  width: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  font-weight: 700;
  padding-bottom: 2px;
`;

const Block = styled.div`
  border-radius: ${commonStyle.borderRadius}px;
  padding: 8px;
  background-color: ${COLORS.classic};
  margin-bottom: 16px;
`;

const Compte = ({ user, updateUser, disconnect }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [editInput, setEditInput] = useState('');
  const [editing, setEditing] = useState(null);
  const [error, setError] = useState(null);
  const [newPrefComm, setNewPrefComm] = useState(null);
  const [newInfoComm, setNewInfoComm] = useState(null);
  const [newInfoProg, setNewInfoProg] = useState(null);
  const history = useHistory();

  const inputRef = React.createRef();

  useEffect(() => {
    if (user) syncUser();
  }, []);

  const syncUser = async () => {
    try {
      const m_user = await userGet(user);
      updateUser(m_user);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // FIXME : Post new password to backend
    try {
      const userUpdated = await newPasswordPost({
        userId: user.id,
        oldPassword,
        newPassword,
      });
      setError(null);
      message.success('Mise à jour réussie');
      setOldPassword('');
      setNewPassword('');
    } catch (error) {
      console.error(error);
      setError(
        error.status === 400 ? 'Mauvais mot de passe' : 'Erreur de mise à jour'
      );
    }
  };

  const handleEdit = async (s) => {
    try {
      if (editing === s) {
        setEditing(null);
        const m_user = await userPut({ user, data: { [s]: editInput } });
        if (m_user) await syncUser();
      } else {
        setEditing(s);
        setEditInput(user[s] || '');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePrefs = async () => {
    try {
      if (newInfoComm === null && newInfoProg === null && newPrefComm === null)
        return;
      const newSettings = {
        infoComm:
          typeof newInfoComm === 'boolean'
            ? newInfoComm
            : user.settings.infoComm,
        infoProg:
          typeof newInfoProg === 'boolean'
            ? newInfoProg
            : user.settings.infoProg,
        prefComm: newPrefComm ? newPrefComm : user.settings.prefComm,
      };
      const update = await userSettingsPut({
        settingsId: user.userSettingId,
        settings: newSettings,
      });
      updateUser({ ...user, settings: newSettings });
      setNewInfoComm(null);
      setNewInfoProg(null);
      setNewPrefComm(null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <>
      <PageHeader title={'Votre compte'} />
      <List>
        <List.Item label={'Nom :'}>
          <H4>{user?.lastname}</H4>
        </List.Item>
        <List.Item label={'Prénom :'}>
          <H4>{user?.firstname}</H4>
        </List.Item>
        <List.Item label={'Date de naissance :'}>
          <H4>{user?.birthdate || 'N/C'}</H4>
        </List.Item>
        <List.Item label={'Email :'}>
          <Row>
            {editing === 'email' ? (
              <EditInput
                ref={inputRef}
                value={editInput}
                onChange={(e) => setEditInput(e.target.value)}
              ></EditInput>
            ) : (
              <H4>{user?.email}</H4>
            )}
            <IconEdit
              className={`icon-dbi-pic-_${
                editing === 'email' ? 'valider' : 'modifier'
              }`}
              onClick={() => handleEdit('email')}
            />
          </Row>
        </List.Item>
        <List.Item label={'Téléphone :'}>
          <Row>
            {editing === 'phone' ? (
              <EditInput
                ref={inputRef}
                value={editInput}
                onChange={(e) => setEditInput(e.target.value)}
              ></EditInput>
            ) : (
              <H4>{user?.phone || 'N/C'}</H4>
            )}
            <IconEdit
              className={`icon-dbi-pic-_${
                editing === 'phone' ? 'valider' : 'modifier'
              }`}
              onClick={() => handleEdit('phone')}
            />
          </Row>
        </List.Item>
        <List.Item label={'Adresse :'}>
          <Row>
            {editing === 'address' ? (
              <EditInput
                ref={inputRef}
                value={editInput}
                onChange={(e) => setEditInput(e.target.value)}
              ></EditInput>
            ) : (
              <H4>{user?.address || 'N/C'}</H4>
            )}
            <IconEdit
              className={`icon-dbi-pic-_${
                editing === 'address' ? 'valider' : 'modifier'
              }`}
              onClick={() => handleEdit('address')}
            />
          </Row>
        </List.Item>
      </List>
      <Block>
        <List>
          <H4>
            <span
              className={`icon-dbi-pic-_Message`}
              style={{ fontSize: 14, paddingRight: 8 }}
            />
            Préférences de communication :
          </H4>
          <List.Item label={'Recevoir les appels de fonds :'}>
            {user?.settings?.prefComm && (
              <Select
                name='prefComm'
                onChange={(e) => setNewPrefComm(e.target.value)}
                selected={user?.settings?.prefComm}
                options={[
                  { value: 'Par courrier' },
                  { value: 'Par email' },
                  { value: 'Par email et courrier' },
                ]}
              />
            )}
          </List.Item>
          <List.Item label={'Abonnement aux informations :'}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                label={'Informations commerciales'}
                name='infoscomm'
                defaultChecked={user?.settings?.infoComm}
                onChange={(e) => setNewInfoComm(e.target.checked)}
              />
              <Checkbox
                label={'Informations sur le programme'}
                name='infosprog'
                defaultChecked={user?.settings?.infoProg}
                onChange={(e) => setNewInfoProg(e.target.checked)}
              />
            </div>
          </List.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              primary
              style={{ marginTop: 24 }}
              onClick={updatePrefs}
              disabled={
                newInfoComm === null &&
                newInfoProg === null &&
                newPrefComm === null
              }
            >
              Enregistrer les préférences
            </Button>
          </div>
        </List>
      </Block>
      <Block style={{ paddingTop: 24, paddingBottom: 24 }}>
        <H4 style={{ marginLeft: 8 }}>
          Vous souhaitez changer votre mot de passe :{' '}
        </H4>
        <form>
          <div style={{ margin: 16 }}>
            <span style={{ marginLeft: 16, fontSize: 12 }}>
              Ancien mot de passe :{' '}
            </span>
            <br />
            <Input
              type='password'
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              style={{
                backgroundColor: COLORS.background,
                color: COLORS.primary,
                marginLeft: 0,
                width: 'calc(100% - 28px)',
              }}
              placeholder='Ancien ...'
            ></Input>
            <br />
            <span style={{ marginLeft: 16, fontSize: 12 }}>
              Nouveau mot de passe :{' '}
            </span>
            <br />
            <Input
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{
                backgroundColor: COLORS.background,
                color: COLORS.primary,
                marginLeft: 0,
                width: 'calc(100% - 28px)',
              }}
              placeholder='Nouveau ...'
            ></Input>
          </div>
          <ErrorBox error={error} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type='submit'
              primary
              onClick={handleSave}
              disabled={!oldPassword || !newPassword}
            >
              Enregistrer
            </Button>
          </div>
        </form>
      </Block>
      <Button
          style={{
            padding: '8px 32px',
            margin:'auto',
            display:'flex'
          }}
          onClick={() => {
            disconnect();
            history.push('/');
          }}
      >
        <span
            className={`icon-dbi-pic-_Disconnect`}
            style={{
              fontSize: 24,
              marginRight: 16,
            }}
        />
        Déconnexion
      </Button>
    </>
  );
};

export { Compte };

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';

const LoaderStyled = styled.div`
  position: fixed;
  background: url('/images/loader_background.png'), ${COLORS.primary};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  width: 100vw;
  height: 100vh;
  visibility: ${(props) => (!props.isLoading ? 'hidden' : 'visible')};
  transition: visibility 0.1s 1s, opacity 0.7s linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <LoaderStyled isLoading={loading}>
      <img
        src='/images/logo.png'
        style={{
          width: 100,
          height: 'auto',
          objectFit: 'contain',
        }}
      ></img>
    </LoaderStyled>
  );
};

export { Loader };

import React from 'react';
import { DocumentCard, H4, PageHeader } from '../Components';

const Documents = ({ lot }) => {
  return (
    <>
      <PageHeader
        title={'Vos documents :'}
        /* subtitle={
          'Nec et nisl nec leo laoreet laoreet at eu mi. Donec scelerisque eleifend massa, quis hendrerit orci malesuada eget. Sed laoreet metus nec nibh luctus.'
        }*/
      />
      <H4 style={{ margin: 8 }}>Vos contrats :</H4>
      {lot?.documents.filter((doc) => doc.objectsTypeId === 5).length === 0 && (
        <p style={{ marginLeft: 8, fontStyle: 'italic' }}>
          Aucun contrat à afficher pour le moment
        </p>
      )}
      {lot?.documents
        .filter((doc) => doc.objectsTypeId === 5)
        .map((doc) => (
          <DocumentCard
              style={{ marginBottom: 8 }}
            key={doc.name}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        ))}
      <H4 style={{ margin: 8, marginTop: 16 }}>Vos demandes :</H4>
      {lot?.documents.filter((doc) => [4, 6, 8].includes(doc.objectsTypeId))
        .length === 0 && (
        <p style={{ marginLeft: 8, fontStyle: 'italic' }}>
          Aucune demande pour le moment.
        </p>
      )}
      {lot?.documents
        .filter((doc) => [4, 6, 8].includes(doc.objectsTypeId))
        .map((doc) => (
          <DocumentCard
            style={{ marginBottom: 8 }}
            key={doc.name}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        ))}
        {/* <H4 style={{ margin: 8, marginTop: 16 }}>Autres documents :</H4>
      {lot?.documents.filter((doc) => [11].includes(doc.objectsTypeId))
        .length === 0 && (
        <p style={{ marginLeft: 8, fontStyle: 'italic' }}>
          Aucune documentation à afficher pour le moment.
        </p>
      )}
      {lot?.documents
        .filter((doc) => [11].includes(doc.objectsTypeId))
        .map((doc, i) => (
          <DocumentCard
            style={{ marginBottom: 8 }}
            key={doc.name  +  i}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        )) */}
    </>
  );
};

export { Documents };

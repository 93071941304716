import React, { useState } from 'react';
import { userLogin } from '../API';
import {
  Button,
  ErrorBox,
  H1,
  H2,
  Input,
  LoginHeaderWrapper,
} from '../Components';
import { COLORS } from '../config';

export const Login = (props) => {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) return setError('Champs vide...');
    setError(null);
    setLoadingLogin(true);
    try {
      const resp = await userLogin({ email, password, token });
      if (!resp.authent === 'success') throw new Error(resp);
      props.handleLogin({ user: resp.user, accessToken: resp.accessToken });
    } catch (error) {
      setError(
        error?.status === 404
          ? 'Utilisateur non trouvé'
          : error?.status === 401
          ? 'Mauvais mot de passe'
          : 'Erreur de connexion'
      );
      setLoadingLogin(false);
    }
  };

  const automaticLogin = async (e) => {
    try {
      const resp = await userLogin({ email, password, token });
      if (!resp.authent === 'success') throw new Error(resp);
      props.handleLogin({ user: resp.user, accessToken: resp.accessToken });
    } catch (error) {
      setError(
          error?.status === 404
              ? 'Utilisateur non trouvé'
              : error?.status === 401
              ? 'Mauvais mot de passe'
              : 'Erreur de connexion'
      );
      setLoadingLogin(false);
    }
  };

  if (token) {
    automaticLogin();
  }


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%',
        textAlign: 'center',
      }}
    >
      <LoginHeaderWrapper>
        <H1 style={{ marginTop: 0 }}>ESPACE CLIENT</H1>
        <H2 style={{ marginTop: 64 }}>Connexion</H2>
        <form style={{ marginBottom: 50 }}>
          <Input
              className={"inputlogin"}
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            style={{ minWidth: 200 }}
            placeholder='Identifiant'
          ></Input>
          <Input className={"inputlogin"}
            value={password}
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            style={{ minWidth: 200 }}
            placeholder='Mot de passe'
          ></Input>
          <ErrorBox error={error} />
          <div style={{ margin: 8 }}>
            <Button
              type='submit'
              loading={loadingLogin}
              disabled={!email || !password}
              onClick={handleLogin}
              style={{ minWidth: 150 }}
            >
              Connexion
            </Button>
          </div>
        </form>
        {/*<a
          href='/'
          style={{
            fontSize: 12,
            fontWeight: 300,
            fontStyle: 'italic',
            textDecoration: 'underline',
            color: COLORS.classic,
          }}
        >
          identifiant ou mot de passe oublié ?
        </a>*/}
      </LoginHeaderWrapper>
      {/* <div style={{ marginTop: 32 }}>
        <p style={{ margin: 0 }}>Support espace client</p>
        <p style={{ margin: 0, fontWeight: 800 }}>01 23 45 67 89</p>
      </div> */}
    </div>
  );
};
